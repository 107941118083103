<template>
  <div class="row my-4 mx-1 justify-content-center">
    <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
      <div class="background-white">
        <div class="title">
          <div class="position">Installateur</div>
        </div>
      </div>
      <form class="my-4" @submit.prevent="addPRE">
        <div class="center">
          <b-form-group
            label="JE FAIS MOI MÊME LES TRAVAUX ?"
            v-slot="{ ariaDescribedby }"
            class="form-input custom-placeholder legend mt-3"
          >
            <div
              class="d-flex align-items-center form-input custom-placeholder custom-input"
            >
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                class="mb-3"
                name="some-radios"
                value="A"
              >
                OUI
              </b-form-radio>
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                class="mb-3"
                name="some-radios"
                value="B"
              >
                NON
              </b-form-radio>
            </div>
          </b-form-group>

          <div>
            <b-form-group class="mt-4" v-if="selected === 'B'">
              <searchInput
                :list="getAllsousTraitant"
                :loader="getLoadingsousTraitant"
                label="name"
                placeholder="selectionne sous-traitant"
                :searchFunc="allsousTraitant"
                @searchfilter="selectSousTraitant($event)"
                :filtre="{ entreprise: form.id }"
                :with_default="0"
                class="form-input custom-placeholder custom-input"
              >
              </searchInput>
            </b-form-group>

            <b-form-group
              :label="$t('NAME') "
              label-for="name"
              v-if="selected === 'B'"
            >
              <b-form-input
                id="name"
                :placeholder="$t('NAME')"
                class="form-input custom-placeholder custom-input"
                v-model="v$.form3.name.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('name')"
                aria-describedby="name-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.form3.name.$errors"
                id="name-feedback"
              ></error-handle>
              <div v-if="erreurlist.name" class="error-message">
                <ul v-if="Array.isArray(erreurlist.name)">
                  <span v-for="(e, index) in erreurlist.name" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.name }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('SIRET') "
              label-for="num_siret"
              v-if="selected === 'B'"
            >
              <b-form-input
                id="num_siret"
                class="form-input custom-placeholder custom-input"
                v-model="v$.form3.num_siret.$model"
                :state="validateState('num_siret')"
                :placeholder="$t('SIRET')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="num_siret-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.form3.num_siret.$errors"
                id="num_siret-feedback"
              ></error-handle>
              <div v-if="erreurlist.num_siret" class="error-message">
                <ul v-if="Array.isArray(erreurlist.num_siret)">
                  <span v-for="(e, index) in erreurlist.num_siret" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.num_siret }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('RGE') "
              label-for="num_rge"
              class="mb-4 index"
            >
              <searchInput
                v-model="form3.certificat_rge"
                placeholder="sélectionne rge"
                :list="getAllCertificatRges"
                :loader="getLoadingCertificatRge"
                label="numero"
                :multiple="false"
                :limit="1"
                :searchFunc="allCertificatRge"
                @searchfilter="selectCat($event)"
                :filtre="{ entreprise: getOnlineUser.entreprise.id }"
                class="index"
              >
              </searchInput>
            </b-form-group>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";
import searchInput from "../ui/searchInput.vue";
export default {
  components: {
    errorHandle,
    searchInput,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },

  data() {
    return {
      soustraitantData: null,
      page: 1,
      per_page: 10,
      selected: "B",
      erreurlist: {
        name: null,
        num_siret: null,
      },
      selectedSousTraitant: false,
      form3: {
        name: null,
        num_siret: null,
        num_rge: null,
        certificat_rge: null,
      },
      form: {},
      errorS: false,
      box: "",
    };
  },
mounted() {
    console.log("soustraitantDataFromStore initially:", this.soustraitantDataFromStore);
    this.soustraitantData = this.soustraitantDataFromStore || null;
    
    console.log("Initial soustraitantData:", this.soustraitantData);
    
    if (this.soustraitantData) {
        this.form3 = { ...this.soustraitantData };
    } else {
        // If data is undefined initially, set a timeout or watch to check when it's available
        setTimeout(() => {
            if (this.soustraitantDataFromStore) {
                this.soustraitantData = this.soustraitantDataFromStore;
                this.form3 = { ...this.soustraitantData };
                console.log("Updated soustraitantData after store update:", this.soustraitantData);
            }
        }, 500); // Wait for half a second and check again
    }
}
  ,
  watch: {

    soustraitantDataFromStore(newVal) {
        if (newVal) {
            console.log("Watcher detected change in soustraitantDataFromStore:", newVal);
            this.soustraitantData = newVal;
            this.form3 = { ...newVal };
        }
    },
}
,
  validations() {
    return {
      form3: {
        name: { required, maxLength: maxLength(100) },
        num_siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "store_sousTraitant",
      "storeCertificatRge",
      "all_entreprises",
      "allsousTraitant",
      "allCertificatRge",
      "sousTraitant",
    ]),
    async fetchData() {
      if (this.selected === "A") {
        return await this.allCertificatRge({
          page: 1,
          per_page: 10,
          search: "",
          sous_traitant_id: "",
          entreprise_id: this.getOnlineUser.entreprise.id,
        });
      }
      if (this.selected === "B") {
        return await this.allCertificatRge({
          page: 1,
          per_page: 10,
          search: "",
          sous_traitant_id: this.form3.id,
          entreprise_id: "",
        });
      } else {
        return await this.allCertificatRge({
          page: 1,
          per_page: 10,
          search: "",
          sous_traitant_id: "",
          entreprise_id: "",
        });
      }
    },
    selectCat(certificats) {
      this.form3.certificat_rge = certificats.id;
    },
    selectSousTraitant(sousTraitant) {
      if (sousTraitant && Object.keys(sousTraitant).length > 0) {
        this.form3 = { ...sousTraitant };
        this.selectedSousTraitant = true;
      } else {
        this.selectedSousTraitant = false;
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.form3[name];
      return $dirty ? !$error : null;
    },

    async addPRE() {
      if (this.selected === "B") {
        console.log("form3", this.form3);
        console.log("selectedSousTraitant", this.selectedSousTraitant);
        if (this.selectedSousTraitant) {
          return this.form3;
        }
        if (!this.selectedSousTraitant) {
          const isFormCorrect = await this.v$.$validate();
console.log('isFormCorrect', isFormCorrect)
          if (!isFormCorrect) return;
          this.errorS = null;
          this.erreurlist = {
            name: null,
            num_siret: null,
          };
          const data = {
            name: this.form3.name,
            num_siret: this.form3.num_siret,
            num_rge: "pomp",
          };
          try {
            const res = await this.store_sousTraitant(data);
  if (!res.data || !res.data.id) {
    throw new Error('Failed to retrieve sous_traitant ID.');
  }
    console.log('Sous_traitant created:', res.data.id);
          
            
            
            console.log('2555555555555');
           const soustraitant = await this.sousTraitant(res.data.id);

            this.$emit("updateList");
    
            return soustraitant.data;
          } catch (err) {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorS = err;
            }
          }
        }
      }
      if (this.selected === "A") {
      console.log('getOnlineUser',this.getOnlineUser);
      const datas={id:this.getOnlineUser.entreprise.id,
      num_siret:this.getOnlineUser.entreprise.siret,
      name:this.getOnlineUser.entreprise.lib
      }
           console.log('datas',datas);
        return datas;
      }
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
  },
  computed: {
    ...mapState({
      soustraitantDataFromStore: (state) => state.soustraitant,
    }),
    ...mapGetters([
      "getAllCertificatRges",
      "getLoadingCertificatRge",
      "getOnlineUser",
      "getAllsousTraitant",
      "getLoadingsousTraitant",
    ]),
    certificatRgeOptions() {
      if (!this.certificat_rge || !Array.isArray(this.certificat_rge)) {
        return [];
      }
      return this.certificat_rge.map((item) => ({
        value: item.id,
        text: item.nom,
      }));
    },
  },
};
</script>
<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
  margin-bottom: 25px;
  height: 60%;
}
.center .legend {
  font-size: 16px;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}
.custom-input {
  box-shadow: none;
}
.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.index {
  z-index: 20 !important;
}
</style>
