<template>
  <div class="">
    <div class="row mt-4">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <form @submit.prevent="addCLI">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Client</div>
            </div>
          </div>
          <div class="center borderPointier mt-3 p-3">
            <b-form-group :label="$t('CUSTOMER')" class="style">
              <searchInput
                v-model="inputSearchValue"
                :list="getAllclients"
                :loader="getclientLoading"
                label="full_name"
                placeholder="client"
                :searchFunc="all_clients"
                @searchfilter="selectclient($event)"
                :filtre="{
                  entreprise: form.id,
                }"
              ></searchInput>
            </b-form-group>


  <b-form-group label="Type">
              <b-form-select
                :disabled="selected"
                v-model="v$.form2.type.$model"
                :options="optionsTypeClient"
                :placeholder="$t('Type')"
                aria-describedby="type-feedback"
                :state="validateState('type')"
                :class="{ 'form-control': false }"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-select>
              <error-handle
                :list="v$.form2.type.$errors"
                id="type-feedback"
              ></error-handle>
              <div v-if="erreurlist.type" class="error-message">
                <ul v-if="Array.isArray(erreurlist.type)">
                  <span v-for="(e, index) in erreurlist.type" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.type }}</span>
              </div>
            </b-form-group>






 <div v-if="form2.type == 'type.professionnel'" >
          <h5 class="titresociete">
            {{ $t("CONTACT_S") }}
          </h5>
          <b-form-group :label="$t('SOCIETY') + '*'">
            <b-form-input
              v-model="v$.form2.company.$model"
              oninput="this.value = this.value.toUpperCase()"
            
              aria-describedby="company-feedback"
            ></b-form-input>
          
          </b-form-group>

          <b-form-group :label="$t('STREET') + '*'" label-for="rue">
            <b-form-input
              v-model="v$.form2.rue.$model"
              oninput="this.value = this.value.toUpperCase()"
           
              aria-describedby="rue-feedback"
            ></b-form-input>
           
          
          </b-form-group>
          <b-form-group :label="$t('POSTAL_CODE') + '*'" label-for="cp">
            <b-form-input
              v-model="v$.form2.cp.$model"
              aria-describedby="cp-feedback"
            ></b-form-input>
          
          </b-form-group>
          <b-form-group :label="$t('CITY') + '*'" label-for="ville">
            <b-form-input
              v-model="v$.form2.ville.$model"
              aria-describedby="ville-feedback"
            ></b-form-input>
       
          </b-form-group>
           <b-form-group :label="$t('COUNTRY') ">
                  <multiselect
                    v-model="form2.pays"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllcountries"
                    label="name"
                    track-by="id"
                    :placeholder="$t('COUNTRY')"
                    class="form-input custom-placeholder custom-input"
                  >
                    <template slot="noResult">
                      {{ $t("NO_DATA_FOUND") }}
                    </template>
                  </multiselect>
                
               
                </b-form-group>
          <b-form-group :label="$t('COMPANY_PHONE') + '*'">
            <template>
              <phone @phoneNumber="formatNumberE($event)"></phone>
            </template>
           
            <div
              v-if="form2.company_tel && !phoneValid"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
          </b-form-group>

          <b-form-group :label="$t('COMPANY_EMAIL') + '*'" label-for="email">
            <b-form-input
              v-model="v$.form2.company_email.$model"
              required
              type="email"
            
              aria-describedby="company_email-feedback"
            ></b-form-input>
          
          </b-form-group>
   <h5 class="titresociete">{{ $t("COMPANY_INTER") }}</h5>

          <b-form-group :label="$t('SURNAME') + '*'" label-for="lastname">
            <b-form-input
              v-model="v$.form2.prenom.$model"
              oninput="this.value = this.value.toUpperCase()"
              
              aria-describedby="prenom-feedback"
            ></b-form-input>
   
          </b-form-group>
          <b-form-group :label="$t('NAME') + '*'">
            <b-form-input
              v-model="v$.form2.nom.$model"
              oninput="this.value = this.value.toUpperCase()"
        
              aria-describedby="nom-feedback"
            ></b-form-input>
            
          </b-form-group>
     
        </div>








<div v-else>
          

            <div class="fieldset mt-3">
              <div class="legend mb-1">{{ $t("SURNAME") }}</div>
              <b-form-input
                v-model="v$.form2.prenom.$model"
                :placeholder="$t('PRENOM')"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('prenom')"
                aria-describedby="prenom-feedback"
                class="form-input custom-placeholder custom-input"
              >
                {{ form2.prenom }}
              </b-form-input>
              <error-handle
                :list="v$.form2.prenom.$errors"
                id="prenom-feedback"
              ></error-handle>
              <div v-if="erreurlist.prenom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.prenom)">
                  <span v-for="(e, index) in erreurlist.prenom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.prenom }}</span>
              </div>
            </div>

            <div class="fieldset">
              <div class="legend mb-1">{{ $t("NAME")  }}</div>
              <b-form-input
                v-model="v$.form2.nom.$model"
                :placeholder="$t('NAME')"
                :state="validateState('nom')"
                aria-describedby="nom-feedback"
                oninput="this.value = this.value.toUpperCase()"
                class="form-input custom-placeholder custom-input"
                :class="{ 'form-control': false }"
              >
                {{ form2.nom }}
                <error-handle
                  :list="v$.form2.nom.$errors"
                  id="nom-feedback"
                ></error-handle>
                <div v-if="erreurlist.nom" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.nom)">
                    <span v-for="(e, index) in erreurlist.nom" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.nom }}</span>
                </div>
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-1">{{ $t("STREET") }}</div>
              <b-form-input
                v-model="v$.form2.rue.$model"
                :placeholder="$t('STREET')"
                :state="validateState('rue')"
                aria-describedby="rue-feedback"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
              <error-handle
                :list="v$.form2.rue.$errors"
                id="rue-feedback"
              ></error-handle>
              <div v-if="erreurlist.rue" class="error-message">
                <ul v-if="Array.isArray(erreurlist.rue)">
                  <span v-for="(e, index) in erreurlist.rue" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.rue }}</span>
              </div>
            </div>

            <div class="row mt-3 adresse">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="legend mb-1">{{ $t("POSTAL_CODE") }}</div>
                <b-form-input
                  v-model="v$.form2.cp.$model"
                  :placeholder="$t('POSTAL_CODE')"
                  :state="validateState('cp')"
                  aria-describedby="cp-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.form2.cp.$errors"
                  id="cp-feedback"
                ></error-handle>
                <div v-if="erreurlist.cp" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.cp)">
                    <span v-for="(e, index) in erreurlist.cp" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.cp }}</span>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="legend mb-1">{{ $t("CITY") }}</div>
                <b-form-input
                  v-model="v$.form2.ville.$model"
                  :placeholder="$t('CITY')"
                  :state="validateState('ville')"
                  aria-describedby="ville-feedback"
                  class="form-input custom-placeholder custom-input"
                ></b-form-input>
                <error-handle
                  :list="v$.form2.ville.$errors"
                  id="ville-feedback"
                ></error-handle>
                <div v-if="erreurlist.ville" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ville)">
                    <span v-for="(e, index) in erreurlist.ville" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ville }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-3 adresse">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group :label="$t('ZONE')">
                  <b-form-select
                    v-model="form2.zone"
                    :options="optionsZone"
                    text-field="text"
                    value-field="value"
                    class="form-input custom-placeholder custom-input"
                  >
                    <template #first>
                      <b-form-select-option :value="null" :disabled="selected"
                        >-- {{ $t("ZONE") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <div v-if="erreurlist.zone" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.zone)">
                      <span v-for="(e, index) in erreurlist.zone" :key="index">
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.zone }}</span>
                  </div>
                </b-form-group>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <b-form-group :label="$t('COUNTRY') ">
                  <multiselect
                    v-model="form2.pays"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllcountries"
                    label="name"
                    track-by="id"
                    :placeholder="$t('COUNTRY')"
                    class="form-input custom-placeholder custom-input"
                  >
                    <template slot="noResult">
                      {{ $t("NO_DATA_FOUND") }}
                    </template>
                  </multiselect>
                
               
                </b-form-group>
              </div>
            </div>
          </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">Client</div>
          </div>
        </div>
        <form @submit.prevent="addCLI">     
        <div class="borderPointier mt-3 pt-3 px-3">
          <div v-if="form2.type == 'type.professionnel'" >
          <b-form-group :label="$t('Poste') + '*'">
            <b-form-input
              v-model="v$.form2.poste.$model"
              oninput="this.value = this.value.toUpperCase()"
              :state="validateState('poste')"
              aria-describedby="poste-feedback"
            ></b-form-input>
         
          </b-form-group>

          <b-form-group :label="$t('PHONE') + '*'" label-for="telephone">
            <template>
              <phone @phoneNumber="formatNumber($event)"></phone>
            </template>
            <div v-if="erreurlist.tel" class="error-message">
              <ul v-if="Array.isArray(erreurlist.tel)">
                <span v-for="(e, index) in erreurlist.tel" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.tel }}</span>
            </div>
            <div v-if="v$.form2.tel.$error" class="error-message">
              {{ $t("REQUIRED_FIELD") }}
            </div>
            <div
              v-if="form2.tel && resultValid == false"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
          </b-form-group>

          <b-form-group :label="$t('EMAIL') + '*'" label-for="email">
            <b-form-input
              v-model="v$.form2.email.$model"
              aria-describedby="email-feedback"
              type="email"
            ></b-form-input>
         
          
          </b-form-group>

          <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

          <b-form-group :label="$t('ACCOUNT_OWNER')">
            <b-form-input
              v-model="v$.form2.titulaire_compte.$model"
              aria-describedby="titulaire_compte-feedback"
            ></b-form-input>
          
           
          </b-form-group>

          <b-form-group label="IBAN" label-for="IBAN">
            <b-form-input
              v-model="v$.form2.IBAN.$model"
              :state="validateState('IBAN')"
              aria-describedby="IBAN-feedback"
            ></b-form-input>
       
        
          </b-form-group>

          <b-form-group label="SWIFT_BIC" label-for="SWIFT_BIC">
            <b-form-input
              v-model="v$.form2.SWIFT_BIC.$model"
              aria-describedby="SWIFT_BIC-feedback"
              oninput="this.value = this.value.toUpperCase()"
            ></b-form-input>
          
           
          </b-form-group>

          <hr />

          <b-form-group :label="$t('COMMENTAIRE')" label-for="commentaire">
            <b-form-textarea
              v-model="form2.commentaire"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
           
          </b-form-group>









        </div>
           <div v-else >
             <div class="fieldset">
              <div class="legend mb-1">{{ $t("EMAIL") }}</div>
              <b-form-input
                type="email"
                v-model="v$.form2.email.$model"
                :placeholder="$t('CLIENT_EMAIL')"
                :state="validateState('email')"
                aria-describedby="email-feedback"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
              <error-handle
                :list="v$.form2.email.$errors"
                id="email-feedback"
              ></error-handle>
              <div v-if="erreurlist.email" class="error-message">
                <ul v-if="Array.isArray(erreurlist.email)">
                  <span v-for="(e, index) in erreurlist.email" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.email }}</span>
              </div>
            </div>
            <div class="fieldset mb-4" v-if="!selected">
              <b-form-group
                    v-model="form2.tel.phone_number"
               
                label-for="telephone"
                class="mb-1 mt-3"
              >
                <template>
                  <phone
                    v-model="form2.tel.phone_number"
                    @phoneNumber="formatNumber($event)"
                    class="form-input custom-placeholder custom-input"
                  ></phone>
                </template>
                <div v-if="erreurlist.tel" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.tel)">
                    <span v-for="(e, index) in erreurlist.tel" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.tel }}</span>
                </div>
                <div v-if="v$.form2.tel.$error" class="error-message">
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div
                  v-if="form2.tel.phone_number && resultValid == false"
                  class="error-message"
                >
                  {{ $t("ERROR_PHONE") }}
                </div>
              </b-form-group>
            </div>

            <b-form-group v-if="selected" :label="$t('phone')" class="mt-2">
              <b-form-input
                class="form-input custom-placeholder custom-input"
                v-model="form2.tel.phone_number"
        
              >
              </b-form-input>
            </b-form-group>
          
            <b-form-group :label="$t('précaire')" class="mt-2">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="form2.precarite"
                placeholder="précaire"
                :options="optionsprécaire"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("précaire") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
             
        </div> 
         </div>
        </form>


        <form @submit.prevent="submitForm">
          <div class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">Logement</div>
              </div>
            </div>
            <b-form-group :label="$t('TYPE_CHAUFFAGE')" class="mt-2">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="logement.type_chauffage"
                placeholder="TYPE_CHAUFFAGE"
                :options="optionstypechauffage"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group :label="$t('TYPE_LOGEMENT')">
              <b-form-select
                class="form-input custom-placeholder custom-input"
                v-model="logement.type_logement"
                :options="optionstypelogement"
                text-field="text"
                value-field="value"
                placeholder="TYPE_LOGEMENT"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('Ancienté') ">
                  <b-form-input
                    type="number"
                    :placeholder="$t('Ancienté')"
                    v-model="logement.anciente"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('Surface') ">
                  <b-form-input
                    type="number"
                    :placeholder="$t('Surface')"
                    v-model="logement.surface"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group :label="$t('Parcelle Cadastrale') " class="mb-4">
              <b-form-input
                type="text"
                :placeholder="$t('Parcelle Cadastrale')"
                v-model="logement.ParcelleCadastrale"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import phone from "@/components/ui/phone.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import searchInput from "@/components/ui/searchInput";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";
export default {
  props: {
    logement: {
      type: Object,
      required: true,
    },
  },
  components: {
    errorHandle,
    searchInput,
    phone,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  activated() {
    console.log("Component re-activated. Check form2 data:", this.form2);
  },

  mounted() {
    this.all_countries({ page: this.page, perPage: 100000 });

    this.clientData = this.clientDataFromStore.client;
    console.log("clientData", this.clientData);
    if (this.clientData) {
      this.form2 = { ...this.clientData };
    }
  },
  watch: {
    clientDataFromStore(newVal) {
      this.clientData = newVal;
    },
    inputSearchValue(newValue) {
      if (newValue.trim() === "") {
        this.selected = false;
      }
    },
  },

  data() {
    return {
      clientData: null,
      inputSearchValue: null,
      errorS: null,
      resultValid: null,
      phoneValid: null,
      selected: false,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      form: {},
      form2: {
        type: "type.particulier",
        précaire: null,
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        pays: "Tunisia",
        entreprise: null,
        company: null,
        company_email: null,
        company_tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        IBAN: null,
        titulaire_compte: null,
        poste: null,
        commentaire: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        IBAN: null,

        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      devis: {
        ParcelleCadastrale: null,
        surface: null,
        ancienté: null,
      },
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      optionsprécaire: [
        { value: "Classique", text: this.$t("Classique") },
        { value: "Précaire", text: this.$t("Précaire") },
      ],
      optionstypechauffage: [
        { value: "combustible_bois", text: this.$t("Combustible/Bois") },
        { value: "electrique", text: this.$t("Electrique") },
        { value: "gaz", text: this.$t("Gaz") },
        { value: "fuel", text: this.$t("Fuel") },
        { value: "bois", text: this.$t("Bois") },
        { value: "pac", text: "PAC" },
      ],
      optionstypelogement: [
        { value: "maison_individuelle", text: this.$t("Maison individuelle") },
        { value: "appartement", text: this.$t("Appartement") },
      ],
      search: "",
      isOwner: true,
    };
  },
  validations() {
    return {
      form2: {
        type: { required },
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        tel: {
          phone_number: { required },
        },
        pays: { required },
        entreprise: { requiredIf: requiredIf(this.isSuperAdmin) },
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        company_tel: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  computed: {
    ...mapState({
      clientDataFromStore: (state) => state.client,
    }),
    ...mapGetters([
     
      "getAllcountries",
      "getAllclients",
      "getclientLoading",
      "getOnlineUser",
      "getPays",
      "getclient",
      "getAllentreprises",
      "getdictionnaire",
      "getSelectedLang",
    ]),
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isProfessionel() {
      return this.form2.type == "type.professionnel";
    },
  },

  methods: {
    ...mapActions([
      "edit_client",
      "storeLogement",
      "all_clients",
      "all_users",
      "pays",
      "client",
      "store_client",
      "all_countries",
    ]),

    selectclient(client) {
      if (client && Object.keys(client).length > 0) {
        this.form2 = { ...client };
        this.selected = true;
      } else {
        this.form2 = {
          type: "type.particulier",
          précaire: null,
          nom: null,
          prenom: null,
          email: null,
          rue: null,
          cp: null,
          ville: null,
          tel: {
            phone_number: null,
            phone_iso_code: null,
          },
          pays: "Tunisia",
          entreprise: null,
          company: null,
          company_email: null,
          company_tel: {
            phone_number: null,
            phone_iso_code: null,
          },
          SWIFT_BIC: null,
          IBAN: null,
          titulaire_compte: null,
          poste: null,
          commentaire: null,
          zone: null,
          precarite: null,
          type_chauffage: null,
          type_logement: null,
        };
        this.selected = false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.v$.form2[name];
      return $dirty ? !$error : null;
    },

    formatNumber(event) {
      if (event && event.number && event.country) {
        this.form2.tel = {
          phone_number: event.number,
          phone_iso_code: event.country.iso2,
        };
        this.resultValid = event.isValid;
      }
    },

    formatNumberE(event) {
      this.form2.company_tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },
    async addCLI() {
      console.log("form2", this.form2);
      console.log("selected", this.selected);
      if (this.selected) {
        const loges = {
          client_id: this.form2.id,
          parcelle_cadastrale: this.logement.ParcelleCadastrale,
          type_chauffage: this.logement.type_chauffage,
          type: this.logement.type_logement,
          surface: this.logement.surface,
          ancienter: this.logement.anciente,
        };
        console.log(' this.logement.ParcelleCadastrale', this.logement.ParcelleCadastrale)
       
        const updatedClientResponse = await this.edit_client({
          data: this.form2,
        });
        const client = await this.client(this.form2.id);
        console.log("Updated client response:", updatedClientResponse);

        const res = await this.storeLogement(loges);
        console.log("res", res);

        return {
          updatedClient: client,
          logement: res,
        };
      }
      if (!this.selected) {
        const isFormCorrect = true;
        // const isFormCorrect = await this.v$.$validate();
        console.log("isFormCorrect", isFormCorrect);
        if (!isFormCorrect) return;
        this.erreurlist = {
          nom: null,
          prenom: null,
          email: null,
          rue: null,
          cp: null,
          ville: null,
          tel: null,
          type: null,
          entreprise: null,
          pays: null,
          SWIFT_BIC: null,
          IBAN: null,
          titulaire_compte: null,
          commentaire: null,
          company: null,
          company_email: null,
          company_tel: null,
          zone: null,
          precarite: null,
          type_chauffage: null,
          type_logement: null,
        };
        this.errorS = null;
        if (this.resultValid == false) return;

        if (this.form2.company_tel && this.phoneValid == false) return;

        const data = {
          type: this.form2.type,
          nom: this.form2.nom,
          prenom: this.form2.prenom,
          email: this.form2.email,
          rue: this.form2.rue,
          cp: this.form2.cp,
          zone:this.form2.zone,

          ville: this.form2.ville,
          pays: this.form2.pays.iso_code,
        };

        if (this.form2.tel && this.form2.tel.phone_number) {
          var num = this.form2.tel.phone_number.indexOf(" ");
          data.phone_number = this.form2.tel.phone_number
            .slice(num + 1)
            .split(" ")
            .join("");
          data.tel = data.phone_number;
          data.phone_iso_code = this.form2.tel.phone_iso_code;
        }
        if (this.form2.type == "type.professionnel") {
          var numE = this.form2.company_tel.phone_number.indexOf(" ");
          data.company_tel = this.form2.company_tel.phone_number
            .slice(numE + 1)
            .split(" ")
            .join("");

          data.company_phone_number = data.company_tel;
          data.company_phone_iso_code = this.form2.company_tel.phone_iso_code;
        }

        if (this.isCommercialBatigo) {
          data.entreprise_id = this.$route.query.entreprise;
        }
        if (this.isSuperAdmin) {
          if (this.form2.entreprise)
            data.entreprise_id = this.form2.entreprise.id;
          else data.entreprise_id = this.getOnlineUser.entreprise.id;
        }
        if (this.form2.zone) {
          data.zone = this.form2.zone;
        }
        if (this.form2.precarite) {
          data.precarite = this.form2.precarite;
        }
        if (this.form2.type_chauffage) {
          data.type_chauffage = this.form2.type_chauffage;
        }
        if (this.form2.type_logement) {
          data.type_logement = this.form2.type_logement;
        }
        if (this.form2.company) {
          data.company = this.form2.company;
        }
        if (this.form2.titulaire_compte) {
          data.titulaire_compte = this.form2.titulaire_compte;
        }
        if (this.form2.poste) {
          data.poste = this.form2.poste;
        }
        if (this.form2.commentaire) {
          data.commentaire = this.form2.commentaire;
        }
        if (this.form2.SWIFT_BIC) {
          data.SWIFT_BIC = this.form2.SWIFT_BIC;
        }
        if (this.form2.IBAN) {
          data.IBAN = this.form2.IBAN;
        }
        if (this.form2.company_email) {
          data.company_email = this.form2.company_email;
        }
        if (this.form2.poste) {
          data.poste = this.form2.poste;
        }

          try {
            const response = await this.store_client(data);

            this.errorS = null;
            this.$emit("affichage", response);
            this.$emit("updateList");
            if (!response) {
              const loge = {
                client_id: response.id,
                parcelle_cadastrale: this.logement.ParcelleCadastrale,
                type_chauffage: this.logement.type_chauffage,
                type: this.logement.type_logement,
                surface: this.logement.surface,
                ancienter: this.logement.anciente,
              };
              const res = await this.storeLogement(loge);
              console.log("res", res);
              // return response;
              return {
                updatedClient: response,
                logement: res,
              };
            }
          } catch (err) {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorS = err;
            }
            throw err;
          }
        
      }
    },

    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.form-control {
  box-shadow: none;
}
.custom-input {
  box-shadow: none;
}
.col-md-12 {
  padding: 0px 15px 0px 15px !important;
}
::v-deep .multiselect__tags {
  border: 1px solid #ced4da !important;
  font-size: 13px !important;
}
::v-deep .multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
::v-deep .multiselect__placeholder {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
::v-deep .multiselect__single {
  font-size: 13px !important;
}
.legend {
  font-size: 16px;
}
</style>
