<template>
  <div>
    <div class="row my-4 mx-1 justify-content-center">
      <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
        <div class="background-white">
          <div class="title">
            <div class="position">Adresse de travaux</div>
          </div>
        </div>
        <form class="my-4">
          <div class="my-3">
            <b-form-checkbox
              v-model="address.same_travaux_address"
              :label="$t('QUESTION_ADRESSE')"
              class="custom-input"
            >
              Utiliser la même adresse du client pour les travaux.
            </b-form-checkbox>
          </div>

          <!-- Affichage des champs d'adresse de travaux -->
          <div v-if="!address.same_travaux_address">
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                v-model="address.rue_travaux"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                v-model="address.cp_travaux"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                v-model="address.ville_travaux"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>

          <!-- Affichage des données du client -->
          <div v-if="address.same_travaux_address">
            <b-form-group :label="$t('TRAVAUX_RUE')">
              <b-form-input
                :value="client.updatedClient.data.rue || ''"
                :placeholder="$t('TRAVAUX_RUE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_CP')">
              <b-form-input
                :value="client.updatedClient.data.cp || ''"
                :placeholder="$t('TRAVAUX_CP')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('TRAVAUX_VILLE')">
              <b-form-input
                :value="client.updatedClient.data.ville || ''"
                :placeholder="$t('TRAVAUX_VILLE')"
                class="form-input custom-placeholder custom-input"
                readonly
              ></b-form-input>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      default: () => ({ data: { rue: '', cp: '', ville: '' } }),
    },
  },
  watch: {
    'address.same_travaux_address': function (newValue) {
      console.log('same_travaux_address changed:', newValue);
      console.log('Client object:', this.client.updatedClient.data);

      if (newValue) {
        this.address.rue_travaux = this.client.updatedClient.data.rue || '';
        this.address.cp_travaux = this.client.updatedClient.data.cp || '';
        this.address.ville_travaux = this.client.updatedClient.data.ville || '';
      } else {
        this.address.rue_travaux = '';
        this.address.cp_travaux = '';
        this.address.ville_travaux = '';
      }

      console.log('Updated address.rue_travaux:', this.address.rue_travaux);
    },
    client: function (newClient) {
      console.log('Client data changed:', newClient);

      if (this.address.same_travaux_address) {
        this.address.rue_travaux = newClient.data.rue || '';
        this.address.cp_travaux = newClient.data.cp || '';
        this.address.ville_travaux = newClient.data.ville || '';
      }
    }
  },
};
</script>


<style scoped>
.borderPointier {
  border: 2px dashed  #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px; 
}

.form-input {
  font-size: 13px; 
  height: 41.1px !important;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem; 
  left: 0; 
  right: 0;
  z-index: 1;
  text-align: center;
}
.custom-input {
  box-shadow: none; 
}
.title {
  color:#24316d;
  position: relative;
  z-index: 2;
  margin: 0; 
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem; 
}
  
</style>