<template>
  <div class="row my-4 mx-1 justify-content-center">
    <div class="col-lg-8 col-sm-12 col-md-10 borderPointier">
      <div class="background-white">
        <div class="title">
          <div class="position">Généralité</div>
        </div>
      </div>
      <form class="mt-4 mb-4" @submit.prevent="submitForm">
        <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
          <b-form-input
            type="date"
            v-model="devis.visite_technique"
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('START') ">
          <b-form-input
            type="date"
            v-model="devis.debut_devis"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('END') ">
          <b-form-input
            type="date"
            v-model="devis.fin_devis"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    devis: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    created() {
      const today = new Date().toISOString().split("T")[0];
      if (!this.devis.visite_technique) {
        this.$set(this.devis, "visite_technique", today);
      }
      if (!this.devis.debut_devis) {
        this.$set(this.devis, "debut_devis", today);
      }
      if (!this.devis.fin_devis) {
        this.$set(this.devis, "fin_devis", today);
      }
    },
    submitForm() {
      this.$emit("update-devis", this.devis);
    },
  },
};
</script>

<style scoped>
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}

.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
</style>
