<template>
  <div class="">
    <form @submit.prevent="addsystemeChaufage">
      <div class="row mt-4">
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">$t('Technical_information')</div>
            </div>
          </div>

          <div class="center borderPointier mt-3 p-3">
            <b-form-group label="$t('Pump_type')">
              <b-form-select
                v-model="info.denomination_temperature"
                :options="optionsType"
                class="form-input custom-placeholder custom-input"
              >
               <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t('Pump_type') }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>

            <div class="fieldset mt-3">
              <div class="legend mb-2">$t('Coefficient_performance')(COP)</div>
              <b-form-input
              type="number"
                v-model="info.coefficient_performance"
                placeholder="Coeffcient de performance"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">
              $t('Seasonal_energy_efficiency')
              </div>
              <b-form-input
              type="number"
                v-model="info.efficacite"
                placeholder="Efficacité"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">$t('Heated_surface')(PAC)</div>
              <b-form-input
              type="number"
                v-model="info.surface_chauffee"
                placeholder="Surface"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </div>
             <b-form-group label="$t('Regulator')">
              <b-form-select
                v-model="info.regulateur_classer"
                :options="optionsTypeClient"
           
                class="form-input custom-placeholder custom-input"
              >
              <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t('Regulator') }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">$t('Equipment_removal')</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
           
            <b-form-group label="$t('Equipment')">
              <b-form-input
                v-model="info.equipement"
                type="text"
                placeholder="$t('Equipment')"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="$t('Energy_type')">
              <b-form-input
                type="text"
                placeholder=" $t('Energy_type') "
                v-model="info.typeCombustible"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {},
      form2: {
        nom: null,
      },
      info: {
        denomination_temperature: null,
        surface_chauffee: null,
        regulateur_classer: null,
        efficacite: null,
        coefficient_performance: null,
        typeCombustible: null,
      },
      optionsType: [
          { value: "Basic", text: this.$t("BASIC") },
        { value: "Moyenne", text: this.$t("MOYENNE") },
        { value: "Haute", text: this.$t("Haute") },
        { value: "Basse", text: this.$t("Basse") },
      ],
      optionsTypeClient: [
        { value: "I", text: this.$t("I") },
        { value: "II", text: this.$t("II") },
        { value: "III", text: this.$t("III") },
        { value: "IV", text: this.$t("IV") },
        { value: "V", text: this.$t("V") },
      ],
    };
  },
  methods: {
    ...mapActions(["storeSystemeChauffage"]),

    async addsystemeChauffage() {
      console.log("Start addsystemeChauffage");
      const data = {
        denomination_temperature: this.info.denomination_temperature||null,
        surface_chauffee: this.info.surface_chauffee||null,
        regulateur_classer: this.info.regulateur_classer||null,
        efficacite: this.info.efficacite||null,
        coefficient_performance: this.info.coefficient_performance||null,
      };
     
      try {
        console.log("data", data);
        console.log("start");
        const response = await this.storeSystemeChauffage(data);
        console.log("rep info systemme", response);
        return response;
      } catch (err) {
        console.log("err", err);
      }
    },
  },
};
</script>

<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none; 
}
</style>
