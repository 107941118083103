<template>
  <div>
    <div class="d-flex justify-content-end mt-4 px-0">
      <b-dropdown text="Ajouter" class="button-pre-style mb-2 colorBtn" right>
        <b-dropdown-item @click="addRow('Product')">Product</b-dropdown-item>
        <b-dropdown-item @click="addRow('Forfait')">Forfait</b-dropdown-item>
        <b-dropdown-item @click="addRow('Main d\'oeuvre')">Main d'oeuvre</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="border">

      <div class="scroll-container">
        <div class="content-wrapper">
          <div class="header d-flex mb-3 px-3 pt-2">
            <div class="header-item" style="flex: 1.5 1 5%">{{ $t("Produit") }}</div>
            <div class="header-item" style="flex: 1 ">{{ $t("REF") }}</div>
            <div class="header-item" style="flex: 3">{{ $t("WORDING") }}</div>
            <div class="header-item" style="flex: 1">{{ $t("QTE") }}</div>
            <div class="header-item" style="flex: 1.5">{{ $t("type_unite") }}</div>
            <div class="header-item" style="flex: 1.5 1 ">{{ $t("PUHT") }}</div>
            <div class="header-item" style="flex: 1">{{ $t("TOTALHT") }}</div>
            <div class="header-item" style="flex: 1 ">{{ $t("VAT") }}</div>
          </div>

          <div v-if="rows.length === 0" class="text-center my-4">
            <p>Aucune données</p>
          </div>
          <div v-else>
            <div v-for="(row, index) in rows" :key="index" class="row-item d-flex">
              <form @submit.prevent="handleSubmit">
                <div class="row m-1">
                  <div class="input-wrapper mx-2" style="flex: 2">
                    <searchInput
                      v-if="row.type === 'Product'"
                      :list="getAllProducts"
                      :loader="getProductLoading"
                      label="lib"
                      placeholder="selectionne product"
                      :searchFunc="all_products"
                      @searchfilter="addProduit($event, index)"
                      :filtre="{ entreprise: form.id }"
                      @change="limitDisplay"
                      :formatter="formatSelectedItems"
                    ></searchInput>
                    <searchInput
                      v-if="row.type === 'Forfait'"
                      :list="getAllforfaits"
                      :loader="getforfaitLoading"
                      label="lib"
                      placeholder="selectionne forfait"
                      :searchFunc="all_forfaits"
                      @searchfilter="addProduit($event, index)"
                      :filtre="{ entreprise: form.id }"
                    ></searchInput>
                    <searchInput
                      v-if="row.type === 'Main d\'oeuvre'"
                      :list="getAllmainoeuvres"
                      :loader="getmainoeuvreLoading"
                      label="lib"
                      placeholder="selectionne Main d'oeuvre"
                      :searchFunc="all_mainoeuvres"
                      @searchfilter="addProduit($event, index)"
                      :filtre="{ entreprise: form.id }"
                    ></searchInput>
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1">
                    <b-form-input v-model="row.ref" maxlength="10" class="custom-input" />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 3">
                    <b-form-input
                      class="custom-input"
                      placeholder="lib"
                      v-model="row.wording"
                      oninput="this.value = this.value.toUpperCase()"
                      required
                      maxlength="100"
                    />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1">
                    <b-form-input
                      class="custom-input"
                      placeholder="QTE"
                      type="number"
                      v-model="row.qte"
                      min="1"
                      required
                    />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1.5">
                    <b-form-select
                      class="custom-input"
                      placeholder="UNITE"
                      v-model="row.type_unite"
                      :options="optionstype"
                      text-field="text"
                      value-field="value"
                      required
                    />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1.5">
                    <b-form-input
                      class="custom-input"
                      placeholder="PUHT"
                      type="number"
                      v-model="row.puht"
                      required
                      step="1"
                      min="0"
                      max="10000000.00"
                    />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1.5">
                    <b-form-input class="custom-input" :value="totalHT(row)" readonly />
                  </div>
                  <div class="input-wrapper mx-2" style="flex: 1">
                    <b-form-select
                      class="custom-input"
                      v-model="row.tva"
                      :options="optionsTVA"
                      required
                    />
                  </div>
                </div>

                <div class="row input-wrapper m-2">
                  <div style="flex: 0.5 1 0%;"></div>
                  <div class="mx-2" style="flex: 3">Description</div>
                </div>
                <div class="row input-wrapper m-2">
                  <div style="flex: 2"></div>
                  <div class="" style="flex: 12">
                    <b-form-textarea
                      class="custom-input"
                      v-model="row.desc"
                      placeholder="Add additional notes here..."
                      rows="5"
                    ></b-form-textarea>
                  </div>
                </div>
                <div class="row m-2">
                  <div style="flex: 13"></div>
                  <div style="flex: 1">
                    <b-button
                      @click="removeRow(index)"
                      class="button-pre-style custom-input"
                    >
                      <b-icon icon="trash"></b-icon>
                    </b-button>
                  </div>
                </div>

                <hr v-if="index < rows.length - 1" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchInput from "@/components/ui/searchInput";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    searchInput,
  },
  data() {
    return {
      form: {},
      groupes: [
        {
          produits: [],
        },
      ],
      rows: [],
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getProductLoading",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getAllentreprises",
      "getOnlineUser",
      "getforfaitLoading",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
  methods: {
    ...mapActions([
      "all_products",
      "all_mainoeuvres",
      "all_forfaits",
      "store_forfait",
      "store_product",
      "store_mainoeuvre",
    ]),
    limitDisplay() {
      this.row.selectedItems = [...this.row.selectedItems];
    },

    formatSelectedItems(selectedItems) {
      let displayText = selectedItems.join(", ");
      if (displayText.length > 100) {
        displayText = displayText.substring(0, 10) + "...";
      }
      return displayText;
    },
    addRow(type) {
      this.rows.push({
        searchInputValue: null,
        type: type,
        ref: "",
        wording: "",
        qte: 1,
        type_unite: "",
        type_ligne:type,
        puht: 0,
        tva: "",
        desc: "",
      });
    },

    addProduit(item, index) {
      if (item && this.rows[index]) {
        const row = this.rows[index];
        const produit = item;
         row.type_cout=0,
          row.information='',
        row.ref = produit.ref || "";
        row.wording = produit.lib || "";

        row.qte = produit.quantite || 1;
        row.type_unite = produit.type_unite || "";
        
        row.puht = produit.unit_price || 0;
        row.tva = produit.tva || "";
        row.desc = produit.desc || "";
        row.searchInputValue = produit;
      }
    },
    async handleSubmit() {
    console.log('...')
      this.groupes[0].produits = [];

      for (const [index, row] of this.rows.entries()) {
        const produit = {
          produit_id: row.searchInputValue?.id || "",
          ref: row.ref || "",
          lib: row.wording || "",
          nom: row.wording || "",
          quantite: parseFloat(row.qte) || 1,
          type_unite: row.type_unite || "",
          unit_price: parseFloat(row.puht) || 0,
          tva: parseFloat(row.tva) || 0,
          desc: row.desc || "",
          type_ligne: row.type || "",
          remise_value: "",
          type_cout:0,
          information:row.desc || "",
          entreprise_id:this.getOnlineUser.entreprise.id
        };
console.log('produit',produit)

        this.groupes[0].produits.push(produit);
console.log('row.searchInputValue',row.searchInputValue)
        if (!row.searchInputValue) {
          try {
            let response;
      
            switch (row.type) {
              case "Forfait":
              console.log('produit',produit)
                response = await this.store_forfait(produit);
                console.log('response forfait',response);
                break;
              case "Main d'oeuvre":
                response = await this.store_mainoeuvre(produit);
                   console.log('response Main',response);
                break;
              case "Product":
               
                response = await this.store_product(produit);
                   console.log('response Product',response);
                break;
              default:
              
                continue;
            }
            if (response && response.data && response.data.produit_id) {
          row.searchInputValue = response.data; 
          produit.produit_id = response.data.produit_id; 
        }
            console.log(
              `Réponse de ${row.type} pour la ligne ${index}:`,
              response
            );
            this.$emit("updateList");
          } catch (err) {
            console.error(
              `Erreur lors du stockage du produit de type "${row.type}" à la ligne ${index}:`,
              err.response ? err.response.data : err
            );
          }
        }
      }

      return this.groupes;
    },
    totalHT(row) {
      return row.qte * row.puht;
    },

    removeRow(index) {
      this.rows.splice(index, 1);
    },
  },
};
</script>

<style>



b-dropdown{
  background-color:#24316d !important;
}
.btn-secondary {
    color: #fff;
    background-color: #24316d !important;
    border-color: #24316d !important;
}

.header-item {
  flex: 1;
}



.header {
    width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}


.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;

}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
   background-color:#24316d !important;
   box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}
.border {
  border: 1px solid #24316d !important;
}








.custom-input {
  box-shadow: none !important;
  width: 100%; /* Ensures input fields take full width */
  height: 38px; /* Fix input field height */
}

.row-item {
  display: flex;
  flex-direction: column; /* Ensures proper alignment */
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.border {
  display: block;
  width: 100%; /* La bordure prend toute la largeur disponible */
  border: 1px solid #24316d; /* Couleur de la bordure */
  margin-top: 20px; /* Marge en haut */
  overflow-x: auto; /* Activer le défilement horizontal */
  box-sizing: border-box; /* Inclut la bordure dans le calcul de la largeur */
}

.scroll-container {
  overflow-x: auto; 
    white-space: nowrap;
}

.content-wrapper {

 min-width: 1000px;
}

.header,
.row-item {
  display: flex; /* Utilisation de Flexbox pour aligner les éléments */
   min-width: 100px; /* Empêche les éléments de se casser sur une nouvelle ligne */
}

.input-wrapper {
  display: flex; /* Alignement des éléments dans les cellules */
  align-items: center; /* Centre verticalement */
  margin: 5px; /* Espacement autour des entrées */
}


</style>
