<template>

  <div class="content">
    <v-stepper v-model="step" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          <template> Généralité </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          <template> Client </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          <template> Installateur </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">
          <template> Adresse </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 5" step="5">
          <template> Information </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 6" step="6">
          <template> Chantier </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 7" step="7">
          <template> Règlement </template>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 8" step="8">
          <template> Devis </template>
        </v-stepper-step>
      </v-stepper-header>
      <div class="stepper-content">
        <component
          :is="currentStepComponent"
          ref="stepComponent"
          :address="address"
          @update-devis="handleUpdateDevis"
          @update-mondataire="handleMondataireUpdate"
          @update-partenaire="handlePartenaireUpdate"
          :devis="devis"
          :info="info"
          :chantier="chantier"
          :id="id"
          :client="client"
          :partenaire="partenaire"
          :mondataire="mondataire"
          :sousTraitant="sousTraitant"
          :regulateurs="regulateurs"
          :logement="logement"
        ></component>
      </div>
    </v-stepper>
    <div class="d-flex justify-content-end mt-4">
      <b-button v-if="step > 1" @click="prevStep" class="button-pre-style m-3">
        Précédent
      </b-button>
      <b-button
        type="submit"
        @click="nextStep"
        class="button-suivant-style m-3"
        >{{ step === 8 ? "Terminer" : "Suivant" }}</b-button
      >
    </div>
  
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "./EventBus.js";
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      clientModified: false,
      savedClientData: null,
      address: {
        same_travaux_address: false,
        rue_travaux: "",
        cp_travaux: "",
        ville_travaux: "",
      },
      chantier: null,
      client: null,
      sousTraitant: null,
      loading: false,
      messages: "",
      showSuccessAlert: false,
      showErrorAlert: false,
      quit: false,
      step: 1,
      id: null,
      logement: {},
      devis: {},
      mondataire: {},
      partenaire: {},
      regulateurs: {},
      info: {},
      data: {
        systeme_chauffage_id: null,
        brouillon: 1,
        remise: 0,
        remise_type: "remise.HT",
        conditions_reglements: null,
        description: null,
        remarque: null,
        moyens_paiement: "cheque_virement_espece",
        prime: "renovation_globale",
        organisme_preteur: null,
        montant_credit: null,
        taux_nominal: null,
        mensualite: null,
        nombre_mensualites: null,
        taeg: null,
        fin_devis: null,
        debut_devis: null,
        mandataire_id: null,
        cout_total_credit: null,
        assurance: false,
        assurance_type: null,
        with_aide: false,
        montant_maprimrenov: null,
        montant_cee: null,
        owner: null,
        logement_id: null,
        client_id: null,
        modele_id: null,
        fin_travaux: null,
        debut_travaux: null,
        visite_technique: null,
        delegatairecopie: null,
        bareme_mpr: null,
        cp_travaux: null,
        ville_travaux: null,
        type_devis: "BAR-TH-171",
        partenaire_id: null,
        // interlocuteur_id:null,
        rue_travaux: null,
        groupes: [
          {
            type_traveaux_id: 1,
            type_piece_id: 116,
            piece: "ss",
            traveaux: "travaux",

            // sous_traitant_id: null,

            produits: [],
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["getOnlineUser"]),
    Entreprise() {
      this.$emit("user", this.getOnlineUser);
      return this.getOnlineUser;
    },
    currentStepComponent() {
      return this.steps[this.step - 1].component;
    },
  },
  methods: {
    ...mapActions(["store_devis"]),
    handleUpdateDevis(updatedDevis) {
      this.devis = { ...this.devis, ...updatedDevis };
    },
    handleMondataireUpdate(mondataire) {
      this.mondataire = { ...this.mondataire, ...mondataire };
      console.log("this.mondataire", this.mondataire);
    },
    handlePartenaireUpdate(partenaire) {
      this.partenaire = { ...this.partenaire, ...partenaire };
      console.log("this.partenaire", this.partenaire);
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },

    async nextStep() {
      const wasStepTwo = this.step === 2;
      const wasStepChantier = this.step === 6;
      const wasStepThree = this.step === 3;
      const wasStepFive = this.step === 5;
      if (this.step < this.steps.length) {
        this.step++;
      } else if (this.step === this.steps.length) {
        this.$emit("complete");
      }
      if (wasStepTwo && this.step === 3) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.addCLI === "function") {
          try {
            console.log("...");

            const response = await stepComponent.addCLI();
            this.client = response;
            
            this.savedClientData = { ...this.client };
            console.log("response", response);

            this.$store.dispatch(
              "updateClientData",
              this.client.updatedClient.data
            );

            console.log("updatedClient", this.client.updatedClient?.data);
            EventBus.$emit("clientAdded", this.client.updatedClient.data?.id);
            EventBus.$emit("logementAdded", this.client.logement.data?.id);
          } catch (error) {
            console.error("Error calling addCLI:", error);
          }
        }
      }
      if (wasStepFive && this.step === 6) {
        const stepComponent = this.$refs.stepComponent;

        if (
          stepComponent &&
          typeof stepComponent.addsystemeChauffage === "function"
        ) {
          try {
            const response = await stepComponent.addsystemeChauffage();
            this.info = response;
            console.log("info", this.response);
            EventBus.$emit("infoAdded", this.info.data?.id);
          } catch (error) {
            console.error("Error calling addsystemeChauffage:", error);
          }
        }
      }
      if (wasStepThree && this.step === 4) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.addPRE === "function") {
          try {
            console.log("soustraitant");
            const res = await stepComponent.addPRE();
            console.log("res sous traitant", res);
            this.sousTraitant = res;
            this.$store.dispatch("updatesousTraitantData", res);
            console.log("sous..", this.sousTraitant);
          } catch (error) {
            console.error("Error calling addPRE:", error);
          }
        }
      }
      if (wasStepChantier && this.step === 7) {
        const stepComponent = this.$refs.stepComponent;

        if (stepComponent && typeof stepComponent.handleSubmit === "function") {
          try {
            const response = await stepComponent.handleSubmit();
            this.chantier = response;

            console.log("response", response);
            console.log("chantier", this.chantier);

            EventBus.$emit("handleSubmit", this.chantier);
          } catch (error) {
            console.error("Error calling handleSubmit:", error);
          }
        }
      }
      if (this.step===8) {
        this.data.brouillon = 0;
      }
    },

    async createDevis() {
      try {
        console.log("Starting createDevis process...");
    console.log('this.sousTraitant?.certificat_rge',this.sousTraitant?.certificat_rge)
        this.data.modele_id = 14;

        this.data.montant_cee = 4000;
        this.data.certificat_rge_id = this.sousTraitant?.certificat_rge|| null;
        this.data.debut_devis =
          this.devis?.debut_devis || new Date().toISOString() || null;
        this.data.fin_devis =
          this.devis?.fin_devis || new Date().toISOString() || null;
        this.data.visite_technique =
          this.devis?.visite_technique || new Date().toISOString() || null;
        this.data.mandataire_id = this.mondataire?.id || null;
        this.data.systeme_chauffage_id = this.info?.data?.id || null;
        this.data.partenaire_id = this.partenaire?.id || null;
        this.data.client_id = this.client?.updatedClient?.data?.id || null;
        this.data.cp_travaux = this.address?.cp_travaux || "";
        this.data.rue_travaux = this.address?.rue_travaux || "";
        this.data.ville_travaux = this.address?.ville_travaux || "";
        this.data.moyens_paiement = this.regulateurs?.moyens_paiement || "";
        this.data.logement_id = this.client?.logement?.data?.id || null;
        this.data.type_devis = "BAR-TH-171";
        this.data.fin_travaux =
          this.regulateurs?.fin_travaux || this.devis?.debut_devis;
        this.data.debut_travaux =
          this.regulateurs?.debut_travaux || this.devis?.debut_devis;
        if (!Array.isArray(this.data.groupes)) {
          this.data.groupes = [{ type_piece_id: "1", produits: [] }];
        }

        if (Array.isArray(this.data.groupes)) {
          this.data.groupes.forEach((group) => {
            group.sous_traitant_id = this.sousTraitant?.id || null;
          });
        }

        if (this.data.groupes.length === 0) {
          this.data.groupes.push({ type_piece_id: "1", produits: [] });
        }

        if (!Array.isArray(this.chantier)) {
          this.chantier = [];
        }

        for (const chantierItem of this.chantier) {
          if (!Array.isArray(chantierItem.produits)) {
            chantierItem.produits = [];
          }

          for (const product of chantierItem.produits) {
            this.data.groupes[0].produits.push({
              produit_id: product.produit_id,
              ref: product.ref,
              lib: product.lib,
              quantite: product.quantite,
              tva: product.tva,
              type_unite: product.type_unite,
              unit_price: product.unit_price,
              type_ligne: product.type_ligne,
              remise_value: product.remise_value,
              desc: product.desc
            });
          }
        }
console.log('this.data.certificat_rge_id',this.data.certificat_rge_id)
        console.log("data", this.data);

        const response = await this.store_devis(this.data);

        console.log("response", response);
        this.id = response.data?.devis?.id;
        console.log("this.id", this.id);
        EventBus.$emit("id", this.id);
          this.$store.commit('CLEAR_CLIENT_DATA');
    this.$store.commit('CLEAR_SOUSTRAITANT_DATA');

        this.loading = false;
        this.messages = response.message;
        this.showSuccessAlert = true;
        this.quit = true;
      } catch (err) {
        console.error("Error details:", err.response ? err.response.data : err);
        this.quit = false;
        this.messages = err.response ? err.response.data.message : err.message;
        this.showErrorAlert = true;
        this.showSuccessAlert = false;
        this.loading = false;
      }
    },
    handleBeforeUnload(event) {
      console.log("handleBeforeUnload");

      this.createDevis();
      event.preventDefault();
      event.returnValue = "";
    },
  },
  created() {
    console.log("created");
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeDestroy() {
    console.log("beforeDestroy");

    window.removeEventListener("beforeunload", this.createDevis());
    console.log("closing");
  },
  watch: {
    client: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.clientModified = true;
          console.log("Client data modified");
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.v-stepper__step--complete .v-stepper__step__step {
  background-color: #24316d !important;
  border-color: #24316d !important;
  color: white !important;
}

.v-stepper__step--active .v-stepper__step__step {
  background-color: #24316d !important;
  border: 1px solid #24316d !important;
  color: white !important;
  box-shadow: 0 0 0 1px #fff inset;
  border-radius: 50%;
}

.v-stepper__step--complete + .v-divider {
  border-color: #24316d !important;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

::before {
  text-decoration: inherit;
  vertical-align: inherit;
  color: white !important;
}
.v-stepper__header {
  height: 72px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: none !important;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  text-shadow: 0px 0px 0px black;
  color: #24316d !important;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.button-pre-style,
.button-suivant-style {
  background-color: #24316d;
  color: white;
  font-size: 13px;
  box-shadow: none !important;
}

.button-suivant-style:hover,
.button-suivant-style:focus,
.button-suivant-style:active {
  background-color: #24316d;
  color: white;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 12px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media only screen and (max-width: 530px) {
  .v-stepper__step--complete + .v-divider {
    border-color: white !important;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 75px;
  }
  .theme--light.v-stepper .v-stepper__header .v-divider {
    border-color: #fff;
  }

  .v-divider {
    display: block;
    /* flex: 1 1 0px; */
    /* max-width: 100%; */
    height: 0px;
    max-height: 0px;
    border: solid;
    border-width: thin 0 0 0;
    transition: inherit;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* flex-basis: 175px; */
  }

  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    /* padding: 24px; */
    /* position: relative; */
  }
}
@media only screen and (max-width: 900px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 10px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 10px;
    padding: 6px 12px;
  }
}

@media only screen and (max-width: 600px) {
  .v-stepper__step--complete .v-stepper__step__step,
  .v-stepper__step--active .v-stepper__step__step {
    font-size: 8px;
  }

  .stepper-buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-pre-style,
  .button-suivant-style {
    font-size: 8px;
    padding: 4px 8px;
  }
}

.stepper-content {
  padding: 0 10px;
}
</style>
